import React from 'react'

export const OverallProgress = ({progress}) => {
  const radius = 60;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (progress / 100) * circumference;
  return (
    <div className="flex flex-col items-center  mt-[5%]">
    <h1 className="text-3xl font-bold mb-2">Overall Progress</h1>
    <p className="text-gray-600 mb-4">Below timeline shows your current progress so-far. Scroll vertically to see details.</p>
    <div className="relative flex items-center justify-center">
      <div className="w-36 h-36 relative">
      <svg className="w-full h-full -rotate-90 transform">
        <circle
          cx="72"
          cy="72"
          r={radius}
          className="stroke-gray-200"
          strokeWidth="12"
          fill="none"
        />
        <circle
          cx="72"
          cy="72"
          r={radius}
          className="stroke-[#7FB04F] transition-all duration-700 ease-in-out"
          strokeWidth="12"
          fill="none"
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
        />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="text-lg font-semibold text-black">
        {progress.toFixed(2)}%
        </span>
      </div>
    </div>
    </div>
  
  </div>
  )
}
