export const getDynamicHeading = (blockNumber) => {
    
    const number = parseInt(blockNumber, 10);
    if (number >= 1 && number <= 16) return "Spark & Explore";
    if (number >= 17 && number <= 44) return "Question & Uncover";
    if (number >= 45 && number <= 60) return "Experiment & Network";
    if (number >= 61 && number <= 74) return "Collaborate";
    if (number >= 75 && number <= 130) return "Exit";
    return "heading"; 
  };
  
  