import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSidebar } from '../common/SidebarContext';

export const TopBar = ({text,button,backArrow}) => {
  const { isSidebarOpen, setIsSidebarOpen } = useSidebar();
const navigate =useNavigate();
  return (
    <div style={{borderColor:"rgba(0, 0, 0, 0.40)"}} className="flex items-center justify-between py-[25px] px-8 w-full bg-white  border-b-[1px]">
        <div className='flex gap-4 cursor-pointer items-center'> 
        {!isSidebarOpen && (
          <button
            className="z-40 p-2"
            onClick={(e)=>setIsSidebarOpen(!isSidebarOpen)}
            aria-label="Open sidebar"
          >
            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 12H21M3 6H21M3 18H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        )}
             
            {backArrow && <img src="/back_arrow.svg" onClick={()=>navigate(-1)} />}
            <h1 className="text-xl font-medium text-black">{text}</h1>
        </div>
       {/* {button && <button onClick={()=>navigate("/admin/createNewAvatar")} className="primary text-white py-2 px-4 rounded-[4px]  cursor-pointer transition duration-200">
                + Create New Avatare
        </button>} */}

    </div>
  )
}
