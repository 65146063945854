import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import SideNav from "../components/common/SideNav";
import { useSidebar } from "../components/common/SidebarContext";

const Main = ({ setTotalCredits, totalCredits, changeCredits }) => {
  const navigate = useNavigate();
  const { isSidebarOpen, setIsSidebarOpen } = useSidebar();
  const token = localStorage.getItem("token");

  const checkLogin = () => {
    return token && token !== 'null';
  };

  useEffect(() => {
    const isLogin = checkLogin();
    if (!isLogin) {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 1024);
    };

    handleResize(); // Set initial state
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

 

  return (
    <div className="relative flex w-full h-screen">
      {/* Sidebar */}
     
      <div 
        className={`fixed h-screen inset-y-0 left-0 z-50 overflow-y-auto bg-white shadow-lg transform ${
          isSidebarOpen ? 'translate-x-0 w-[280px]' : '-translate-x-full w-0'
        } transition-transform duration-300 ease-in-out lg:relative`}
      >
        <button
          className="absolute top-0 right-4 p-2 z-20"
          onClick={(e)=>setIsSidebarOpen(!isSidebarOpen)}
          aria-label="Close sidebar"
        >
          <svg className="w-6 h-6 text-white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
        <SideNav setAllCredits={setTotalCredits} totalCredits={totalCredits} changeCredits={changeCredits} />
      </div>

      {/* Main Content */}
      <div
        className={`flex-1 transition-all duration-300 ease-in-out ${
          isSidebarOpen ? '' : 'lg:ml-0'
        } overflow-y-auto`}
      >
       
        <Outlet />
      </div>
    </div>
  );
};

export default Main;
