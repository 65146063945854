import React from 'react';
import TemplateDetail from '../../components/Template/TemplateDetail';
import SlideViewer from '../../components/Template/SlideViewer';
import { TopBar } from '../../components/CommonComponent/Topbar';
import { useLocation } from 'react-router-dom';

function Template() {
  const location = useLocation();
  const { ...state } = location.state || {};
  return (
    <div>
      <TopBar backArrow={true} text={state.title}/>
      <div className='flex py-16 px-12 bg-[#F7F8FC]'>
       <div className='bg-white w-full flex'> 
      <TemplateDetail  {...state}/>
      <SlideViewer  index={state.index} heading={state.heading} title={state.title} newChat={state.newChat} />
      </div>
      </div>
    </div>
  );
}
export default Template;
